var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { staticStyle: { "box-sizing": "border-box", padding: "30px" } },
        [
          _c("a-form", [
            _c(
              "div",
              { staticStyle: { display: "flex", "flex-direction": "row" } },
              [
                _c(
                  "a-form-item",
                  { staticClass: "table-head-layout", attrs: { label: "" } },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "300px" },
                        on: { change: _vm.changeAppId },
                        model: {
                          value: _vm.reqData.appId,
                          callback: function($$v) {
                            _vm.$set(_vm.reqData, "appId", $$v)
                          },
                          expression: "reqData.appId"
                        }
                      },
                      [
                        _c("a-select-option", { key: "" }, [
                          _vm._v("请选择应用APPID")
                        ]),
                        _vm._l(_vm.mchAppList, function(item) {
                          return _c("a-select-option", { key: item.appId }, [
                            _vm._v(
                              _vm._s(item.appName) +
                                " [" +
                                _vm._s(item.appId) +
                                "]"
                            )
                          ])
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          !_vm.reqData.appId
            ? _c("a-divider", [_vm._v("请选择应用APPID")])
            : _vm.ifCodeList.length == 0
            ? _c("a-divider", [_vm._v("该应用尚未配置任何通道")])
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "paydemo", staticStyle: { width: "100%" } },
                  [
                    _c("div", { staticClass: "paydemo-type-content" }, [
                      _c(
                        "div",
                        { staticClass: "paydemo-type-name article-title" },
                        [_vm._v("选择通道")]
                      ),
                      _c(
                        "div",
                        { staticClass: "paydemo-type-body" },
                        [
                          _vm._l(_vm.ifCodeList, function(item) {
                            return [
                              _c(
                                "div",
                                {
                                  key: item.ifCode,
                                  class: {
                                    "paydemo-type": true,
                                    "color-change": true,
                                    this: _vm.reqData.ifCode === item.ifCode
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeCurrentIfCode(
                                        item.ifCode
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "color-change" }, [
                                    _vm._v(_vm._s(item.ifName))
                                  ])
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "paydemo-form-item" },
                      [
                        _c("span", [_vm._v("入账方式：")]),
                        _c(
                          "a-radio-group",
                          {
                            staticStyle: { display: "flex" },
                            model: {
                              value: _vm.reqData.entryType,
                              callback: function($$v) {
                                _vm.$set(_vm.reqData, "entryType", $$v)
                              },
                              expression: "reqData.entryType"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "a-radio",
                                  {
                                    attrs: {
                                      value: "WX_CASH",
                                      disabled: _vm.reqData.ifCode != "wxpay"
                                    }
                                  },
                                  [_vm._v("微信零钱")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    attrs: {
                                      value: "ALIPAY_CASH",
                                      disabled: _vm.reqData.ifCode != "alipay"
                                    }
                                  },
                                  [_vm._v("支付宝余额")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    attrs: { value: "BANK_CARD", disabled: "" }
                                  },
                                  [_vm._v("银行卡（暂未支持）")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("a-divider"),
                    _c("div", { staticClass: "paydemo-type-content" }, [
                      _c(
                        "div",
                        { staticClass: "paydemo-type-name article-title" },
                        [_vm._v("转账信息")]
                      ),
                      _c("form", { staticClass: "layui-form" }, [
                        _c("div", { staticClass: "paydemo-form-item" }, [
                          _c("label", [_vm._v("订单编号：")]),
                          _c("span", { attrs: { id: "payMchOrderNo" } }, [
                            _vm._v(_vm._s(_vm.reqData.mchOrderNo))
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: " paydemo-btn",
                              staticStyle: { padding: "0 3px" },
                              on: { click: _vm.randomOrderNo }
                            },
                            [_vm._v("刷新订单号")]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("span", [_vm._v("转账金额(元)：")]),
                            _c("a-input-number", {
                              attrs: { max: 100000, min: 0.01, precision: 2 },
                              model: {
                                value: _vm.reqData.amount,
                                callback: function($$v) {
                                  _vm.$set(_vm.reqData, "amount", $$v)
                                },
                                expression: "reqData.amount"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("span", [_vm._v("收款账号：")]),
                            _c("a-input", {
                              staticStyle: {
                                width: "200px",
                                "margin-right": "10px"
                              },
                              model: {
                                value: _vm.reqData.accountNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.reqData, "accountNo", $$v)
                                },
                                expression: "reqData.accountNo"
                              }
                            }),
                            _c(
                              "a-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.reqData.entryType == "WX_CASH",
                                    expression: "reqData.entryType=='WX_CASH'"
                                  }
                                ],
                                attrs: { size: "small", type: "danger" },
                                on: { click: _vm.showChannelUserQR }
                              },
                              [_vm._v("自动获取openID")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-left": "10px", color: "red" }
                          },
                          [
                            _vm._v(
                              "提示：【微信官方】需要填入对应应用收款方的openID"
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "margin-left": "10px", color: "red" }
                          },
                          [_vm._v(" 【支付宝官方】需要填入支付宝登录账号")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "paydemo-form-item",
                            staticStyle: { "margin-top": "10px" }
                          },
                          [
                            _c("span", [_vm._v("收款人姓名：")]),
                            _c("a-input", {
                              staticStyle: { width: "200px" },
                              model: {
                                value: _vm.reqData.accountName,
                                callback: function($$v) {
                                  _vm.$set(_vm.reqData, "accountName", $$v)
                                },
                                expression: "reqData.accountName"
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  color: "red"
                                }
                              },
                              [
                                _vm._v(
                                  "提示： 填入则验证，否则不验证收款人姓名"
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("span", [_vm._v("转账备注：")]),
                            _c("a-input", {
                              staticStyle: { width: "200px" },
                              model: {
                                value: _vm.reqData.transferDesc,
                                callback: function($$v) {
                                  _vm.$set(_vm.reqData, "transferDesc", $$v)
                                },
                                expression: "reqData.transferDesc"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: {
                                  padding: "5px 20px",
                                  "background-color": "#1953ff",
                                  "border-radius": "5px",
                                  color: "#fff"
                                },
                                on: { click: _vm.immediatelyPay }
                              },
                              [_vm._v("立即转账")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                )
              ])
        ],
        1
      ),
      _c("ChannelUserModal", {
        ref: "channelUserModal",
        on: {
          changeChannelUserId: function($event) {
            return _vm.changeChannelUserIdFunc($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }